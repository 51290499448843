"use strict";
module.exports = {
  viewports: {
    mobile: 480,
    tablet: 768,
    desktop: 1024,
    "desktop-wide": 1366,
    "desktop-ultra-wide": 1920
  },
  containerMaxWidth: 1200
};
