"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { deviceObserver } from "project/general/js/device";
import { scrollService } from "project/general/js/scroll-service";
import { AGENTS_DESKTOP_STICKY_NAV_HEIGHT, DESKTOP_STICKY_HEADER_HEIGHT } from "project/general/js/header-sizes";
export default class SmoothScroll extends DcBaseComponent {
  constructor(...args) {
    super(...args);
    __publicField(this, "setOffset", () => {
      const styles = getComputedStyle(document.body);
      const mobileHeaderHeight = +styles.getPropertyValue("--header-mobile-height").replace("px", "");
      const desktopHeaderHeight = +styles.getPropertyValue("--header-desktop-height").replace("px", "");
      const agents = document.querySelector(".agentsClass");
      const topPosition = agents ? AGENTS_DESKTOP_STICKY_NAV_HEIGHT : desktopHeaderHeight - DESKTOP_STICKY_HEADER_HEIGHT;
      return deviceObserver.is(">", "tablet") ? topPosition : mobileHeaderHeight;
    });
    __publicField(this, "initSmoothScroll", () => {
      const linkTargets = [...this.anchors].map((anchor) => document.querySelector(anchor.getAttribute("href"))).filter((anchor) => anchor);
      this.anchors.forEach((element) => {
        element.addEventListener("click", (event) => {
          event.preventDefault();
          const href = element.getAttribute("href");
          window.history.replaceState(null, null, href);
          const target = linkTargets.find((_target) => _target.getAttribute("id") === (href == null ? void 0 : href.replace("#", "")));
          if (target) {
            this.scrollToElement(target);
          }
        });
      });
    });
    __publicField(this, "scrollToElement", (element) => {
      const container = this.refs.container;
      setTimeout(() => {
        scrollService.scrollToElementsTop(element, container, container ? 0 : -this.offset, { duration: 1e3 });
      }, 500);
    });
    this.offset = this.setOffset();
  }
  static getNamespace() {
    return "smooth-scroll";
  }
  onInit() {
    this.anchors = [...this.element.querySelectorAll('[href^="#"]')].filter((anchor) => anchor.getAttribute("href") !== "#");
    if (window.location.hash) {
      const target = document.getElementById(window.location.hash.replace("#", ""));
      if (target) {
        this.scrollToElement(target);
      }
    }
    if (this.anchors.length === 0)
      return;
    deviceObserver.subscribeOnChange(() => {
      this.offset = this.setOffset();
    });
    this.initSmoothScroll();
  }
}
